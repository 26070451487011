.worker {
  &__buttons {
    display: flex;
    justify-content: space-between;
  }
  &__promote-foreman {
    &.ant-btn {
      margin-right: 50%;
    }
  }
  &__demote-worker {
    &.ant-btn {
      margin-right: 50%;
    }
  }
  &__add-worker-button  {
    &.ant-btn {
      margin-bottom: 16px;
      margin-right: 16px;
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    }
  }

  &__add-foreman-button {
    &.ant-btn {
      margin-bottom: 16px;
      margin-right: 16px;
      color: #fa541c;
      background: #fff2e8;
      border-color: #ffbb96;
    }
  }
}
