
.object-building {
  &__transfer {
    &.ant-transfer {
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .ant-transfer-list {
      height: 65vh;
      width: 45%;
    }
  }
  &__card {
    width: 40rem;
    &.ant-card {
      margin: 10px 0;
    }
  }
  &__create_button {
    &.ant-btn {
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    }
    margin-bottom: 16px;
  }

  &__save-button {
    margin-top: 10px;
  }
}
