$body-background: #ffffff;
$text-color: #000000;
$font-size-base: 16px;
$font-family: 'Source Sans Pro', sans-serif;


$border-radius-base: 4px;

$input-placeholder-color: #9A9A9A;

$errorColor: rgba(255, 32, 32, 0.85);
$borderColor: #000000;