body {
  margin: 0;
  padding: 0;
}

div.ant-divider {
  background-color: darkgray;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.add-pointer {
  cursor: pointer;
}

.ts-status-tag {
  min-width: 100px;
  text-align: center;
}

.table-striped-rows tbody tr:nth-child(2n) td {
  background-color: rgb(220, 243, 255);
}
.table-striped-rows tbody tr:nth-child(2n+1) td {
  background-color: rgb(255, 251, 221);
}
.table-row-deleted {
  background-color: rgb(253, 204, 204);
}

.button-restore {
  &.ant-btn {
    background-color: #007b00;
    color: white;
  }

}

.button-restore:hover {
  &.ant-btn {
    background-color: #00a700;
    color: white;
    border: none;
  }
}

.button-restore:focus {
  &.ant-btn {
    background-color: white;
    color: #00a700;
    border: solid 2px #00a700;
  }
}