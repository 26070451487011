
.timesheet {
  &--worker-select {
    &.ant-select {
      margin: 15px 0 10px;
      min-width: 350px;
    }
  }
  &__approve-button {
    &.ant-btn {
      margin-bottom: 10px;
    }
  }
  &__create_button {
    &.ant-btn {
      margin-bottom: 16px;
      margin-left: 16px;
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    }
  }
}
