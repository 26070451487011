.auth{
  height: 100vh;
  display: grid;
}

.auth-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__field {
    width: 100%;
    margin-bottom: 20px;
  }
  &__submit-button.ant-btn {
    width: 100%;
    height: 45px;
    font-size: 18px;
    font-weight: 600;
    background: #b7eb8f;
    &:hover {
      background: #a1cd7f;
      color: black;
      border-color: transparent;
    }
  }

  &__input {

    &:focus.ant-input {
      border-color: $borderColor;
      box-shadow: none;
    }

    &--error.ant-input {
      border-color: $errorColor;

      &:focus {
        border-color: $errorColor;
      }
    }
    &--error.ant-input-password {
      border-color: $errorColor;

      &:focus {
        border-color: $errorColor;
      }
    }
  }

  &__error {
    display: block;
    color: $errorColor;
    font-size: 12px;
  }

  &__reset-pass-link {
    margin-top: 10px;
    align-self: flex-end;
  }
}
