.report {
  &__start-time-corr
  {
    font-size: 12px;
    color: green;
  }
  &__end-time-corr {
    font-size: 12px;
    color: #6f0000;
  }
  &__start-time-raw
  {
    font-size: 12px;
    color: #006300;
  }
  &__end-time-raw {
    font-size: 12px;
    color: #950000;
  }
  &__download_button {
    &.ant-col {
      display: flex;
      justify-content: flex-end;
    }
    .ant-btn {
      margin-left: 10px;
      margin-bottom: 10px;
    }
  }
  &__filter-input {
    &.ant-select {
      margin: 0 15px 10px 0;
      min-width: 350px;
    }
    &.ant-picker {
      margin: 0 15px 10px 0;
    }
  }

  &__summary-total {
    &.ant-table-cell {
      text-align: right;
    }
  }

  &__summary-total_text {
    &.ant-typography {
      margin-right: 10px;
    }
  }

  &--cell-in-bold {
    &.ant-table-cell {
      font-weight: bold;
    }
  }

  &__ordinaryTime {
    font-style: italic;
    color: #00006d;
    font-size: 12px;
  }

  &__overtime {
    font-style: italic;
    color: #6d0000;
    font-size: 12px;
    font-weight: bold;
  }
}
.money-report {
  &__job-site-title {
    font-size: 0.7em;
  }
  &__money-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.7em;
      width: 100%;
      &__regular {
        color: darkgreen;
      }
      &__overtime {
        color: #6d0000;
      }
    }
  }
}