
.timesheet-approval {
  &__check-tag {
    display: flex;
    flex-direction: column;
    span {
      display: block;
      width: fit-content;
    }
  }

  &__filter-select {
    &.ant-select {
      margin: 0 15px 10px 0;
      min-width: 350px;
    }
    &.ant-picker {
      margin: 0 15px 10px 0;
    }
  }

  &__filter-input {
    &.ant-picker {
      margin-left: 10px;
    }
  }
}

