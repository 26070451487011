.header {
  display: flex;
  background-color: #0a438b;
  padding: 15px 20px;

  &--logo {
    margin: 0 8px;
  }

  &--title {
    font-size: 18px;
    color: white;
    margin: 0;
  }
}
