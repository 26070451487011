.company {
  &--create_button {
    &.ant-btn {
      color: #52c41a;
      background: #f6ffed;
      border-color: #b7eb8f;
    }
    margin-bottom: 16px;
  }
}
